<template>
  <v-app>
    <div class="container">
      
      <br>
      <br>
      <v-card
        class="mx-auto"
        max-width="1000"
      >
        <v-card-text>
          <div class="privacyNoticeText">
            <br>
            <br>
            <div data-custom-class="body">
              <strong>
                <span style="font-size: 26px;">
                  <span data-custom-class="title">
                    PRIVACY NOTICE
                  </span>
                </span>
              </strong>
            </div>
            <br>
            <div>
              <span style="color: rgb(127, 127, 127);">
              <strong>
                <span style="font-size: 15px;">
                <span data-custom-class="subtitle">
                  Last updated <span class="question">June 30, 2021</span>
                </span>
                </span>
              </strong>
              </span>
            </div>
            <br>
            <br>
            <br>
            <div style="line-height: 1.5;">
              <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span data-custom-class="body_text">
              Thank you for choosing to be part of our community at <span class="question">heedi</span>, doing business as heedi  ("<strong>heedi</strong>", "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at <span class="question">privacy@heedi.com</span>.
              </span>
              </span>
              
            </div>  
            <br>
      
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">When you visit our website <a href="https://www.heedi.com" target="_blank" data-custom-class="link">https://www.heedi.com</a> (the "<strong>Website</strong>"), use our mobile application, as the case may be (the "<strong>App</strong>") and more generally, use any of our services (the "<strong>Services</strong>", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
            </span></span></p>



            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as, any related services, sales, marketing or events.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <strong>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</strong>
            </span></span></p>

            <br>
            <h2>TABLE OF CONTENTS</h2>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#infocollect')">1. WHAT INFORMATION DO WE COLLECT?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#infouse')">2. HOW DO WE USE YOUR INFORMATION?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#infoshare')">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#whoshare')">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#sociallogins')">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#3pwebsites')">6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#inforetain')">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#infosafe')">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#infominors')">9. DO WE COLLECT INFORMATION FROM MINORS?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#privacyrights')">10. WHAT ARE YOUR PRIVACY RIGHTS?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#DNT')">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#caresidents')">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#policyupdates')">13. DO WE MAKE UPDATES TO THIS NOTICE?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#contact')">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a><br>
            <a data-custom-class="link" @click="scrollToAnchorPoint('#request')">15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a><br>
            <br>
            <br>
            <br>
            <h2><span ref="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</span></h2>
            <br>
            <p><span style="font-size: 15px;">
              <strong>Personal information you disclose to us</strong>
            </span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong><em>In Short:</em></strong>&nbsp;&nbsp;
            <em>We collect personal information that you provide to us.</em>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
            </span></span></p>
  
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:
            </span></span></p>
          
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Personal Information Provided by You.</strong> We collect names; email addresses; and other similar information.
            </span></span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <strong>Social Media Login Data. </strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called "<a data-custom-class="link" @click="scrollToAnchorPoint('#sociallogins')">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.
            </span></span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
            </span></span></p>
        
            <p><span style="font-size: 15px;">
              <strong>Information automatically collected</strong>
            </span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <strong><em>In Short: </em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em>
            </span></span></p>
          
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </span></span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              The information we collect includes:
            </span></span></p>
        
            
            <ul>
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</span>
              </li>
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.</span>
              </li>
            </ul>
            <br>
            <p><span style="font-size: 15px;"> 
              <strong>Information collected through our App</strong>
            </span></p>
          
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <strong><em>In Short:</em></strong>
              <em>We collect information regarding your push notifications, when you use our App.</em>
            </span></span></p>    
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">    
                If you use our App, we also collect the following information:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;"><em>Push Notifications. </em>We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
              </li>
            </ul>
            <br>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">     
              This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
            </span></span></p>
            <br>
            <br>
            <h2><span ref="#infouse">2. HOW DO WE USE YOUR INFORMATION?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
              <strong><em>In Short:  </em></strong><em>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
              We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
              We use the information we collect or receive:
            </span></span></p>

            <ul>
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed "<a data-custom-class="link" @click="scrollToAnchorPoint('#sociallogins')">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" for further information.
              </li>
              
              <li style="line-height: 1.5;"><strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at privacy@heedi.com and be sure to include your name, testimonial location, and contact information.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>Request feedback. </strong>We may use your information to request feedback and to contact you about your use of our services
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To enable user-to-user communications.</strong> We may use your information in order to enable user-to-user communications with each user's consent.
              </li>
              
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To manage user accounts. </strong>We may use your information for the purposes of managing our account and keeping it in working order.
              </li>
              
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To send administrative information to you. </strong>We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To protect our Services. </strong>We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).
              </li>
          
          
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</strong>
              </li>
                
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To respond to legal requests and prevent harm. </strong>If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>Fulfill and manage your orders. </strong>We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the services
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>Administer prize draws and competitions.</strong> We may use your information to administer prize draws and competitions when you elect to participate in our competitions.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested service.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>To send you marketing and promotional communications.</strong> We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "<a data-custom-class="link" href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below).
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>Deliver targeted advertising to you.</strong> We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
              </li>
              
              <li style="line-height: 1.5; color: rgb(89, 89, 89);"><strong>For other business purposes.</strong> We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.
              </li>
            </ul>
            <br>
            <br>
            <br>
            <h2><span ref="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short:</em></strong><em>  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em>
            </span></span></p>
          
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We may process or share your data that we hold based on the following legal basis:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                </span></span>
              </li>
              
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                </span></span>
              </li>
              
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                </span></span>
              </li>
                
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
                <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                </span></span>
              </li>
                
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
                <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                </span></span>
              </li>
            </ul>
            <br>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">      
              More specifically, we may need to process your data or share your personal information in the following situations:
            </span></span></p>
              
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </span></span>
              </li>
              
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data on our behalf about how you interact with our Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
                </span></span>
              </li>
                
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
                <strong>Third-Party Advertisers.</strong> We may use third-party advertising companies to serve ads when you visit or use the Services. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you. 
                </span></span>
              </li>
                
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">   
                <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                </span></span>
              </li>
            </ul>
            <br>  
            <br>
            <br>

            <h2><span ref="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
              <strong><em>In Short:</em></strong><em>  We only share information with the following categories of third parties.</em>
            </span></span></p>  

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">   
              We only share and disclose your information with the following categories of third parties. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "<a data-custom-class="link" @click="scrollToAnchorPoint('#contact')">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>".
            </span></span></p>
          
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                Data Analytics services
                </span>
              </li>
            </ul>
            <br>
            <br>
            <br>
            <h2><span ref="#sociallogins">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">   
              <strong><em>In Short:  </em></strong><em>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</em>
            </span></span></p>


            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">   
              Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Google logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">   
              We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
            </span></span></p> 
            <br>
            <br>
          
            <h2><span ref="#3pwebsites">6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short: </em></strong><em> We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our Website.</em>
            </span></span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              The Services may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
            </span></span></p>
            <br>
            <br>
            
            <h2><span ref="#inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short: </em></strong><em> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em>
            </span></span></p>


            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </span></span></p>  
            <br>
            <br>
            <h2><span ref="#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short: </em></strong><em> We aim to protect your personal information through a system of organizational and technical security measures.</em>
            </span></span></p>


            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
            </span></span></p>

            <br>
            <br>
            <h2><span ref="#infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</span></h2>
            <br>
            
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short:</em></strong><em>  We do not knowingly collect data from or market to children under 18 years of age.</em>
            </span></span></p>
            
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at privacy@heedi.com
            </span></span></p>
            <br>
            <br>
            <h2><span ref="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short:</em></strong><em>In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <a data-custom-class="link" @click="scrollToAnchorPoint('#contact')">contact details</a> provided below. We will consider and act upon any request in accordance with applicable data protection laws.
            </span></span></p>
            
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a data-custom-class="link" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you have questions or comments about your privacy rights, you may email us at office@heedi.com
            </span></span></p>
            
            <p><span style="font-size: 15px;">
              <strong>Account Information</strong>
            </span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you would at any time like to review or change the information in your account or terminate your account, you can:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;">
                Contact us using the contact information provided.
              </li>
            </ul>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
            </span></span></p>
            
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><u>Opting out of email marketing:</u></strong> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;">
                Contact us using the contact information provided.
              </li>
            </ul>
            <br>
            <br>
            <br>
            <h2><span ref="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </span></span></p>

            <br>
            <br>
            <h2><span ref="#caresidents">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong><em>In Short: </em></strong><em> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
            </span></span></p>

            <p><span style="font-size: 15px;">
              <strong>CCPA Privacy Notice</strong>
            </span></p>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              The California Code of Regulations defines a "resident" as:
            </span></span></p>
            

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              (1) every individual who is in the State of California for other than a temporary or transitory purpose and<br>
              (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              All other individuals are defined as "non-residents."
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <strong>What categories of personal information do we collect?</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              We have collected the following categories of personal information in the past twelve (12) months:
            </span></span></p>
        
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td align="center" style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    <br>
                    <strong>Category</strong>
                    <br>
                    <br>
                  </td>
                  <td align="center" style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    <br>
                    <strong>Examples</strong>
                    <br>
                    <br>
                  </td>
                  <td align="center" style="width: 14.9084%; border-right: 1px solid black; border-top: 1px solid black;">
                    <br>
                    <strong>Collected</strong>
                    <br>
                    <br>
                  </td>
                </tr>
                <tr style="padding: 3px;">
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    A. Identifiers
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                    Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; vertical-align: middle; border-right: 1px solid black; border-top: 1px solid black;">
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    B. Personal information categories listed in the California Customer Records statute
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Name, contact information, education, employment, employment history and financial Information
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    
                    C. Protected classification characteristics under California or federal law
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Gender and date of birth
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    D. Commercial information
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Transaction information, purchase history, financial details and payment information
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    NO
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    E. Biometric information
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Fingerprints and voiceprints
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    NO
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    F. Internet or other similar network activity
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    G. Geolocation data
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    
                    Device location
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 14.9084%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    H. Audio, electronic, visual, thermal, olfactory, or similar information
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Images and audio, video or call recordings created in connection with our business activities
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    NO
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px;width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                    I. Professional or employment-related Information
                  </td>
                  <td style="padding: 3px;width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                    Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with usage
                  </td>
                  <td style="padding: 3px;width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                    NO
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; width: 33.8274%;">
                    J. Education information
                  </td>
                  <td style="padding: 3px; border-right: 1px solid black; border-top: 1px solid black; width: 51.4385%;">
                    Student records and directory Information
                  </td>
                  <td style="padding: 3px; text-align: center; border-right: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                    NO
                  </td>
                </tr>
                <tr>
                  <td style="padding: 3px; border-width: 1px; border-color: black; border-style: solid; width: 33.8274%;">
                    K. Inferences drawn from other personal Information
                  </td>
                  <td style="padding: 3px; border-bottom: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; width: 51.4385%;">
                    Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                  </td>
                  <td style="padding: 3px; text-align: center; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                    Yes
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:
            </span></span></p>


            <ul>
              <li style="line-height: 1.5;">
                Receiving help through our customer support channels;
              </li>
              <li style="line-height: 1.5;">
                Participation in customer surveys or contests; and
              </li>
              <li style="line-height: 1.5;">
                Facilitation in the delivery of our Services and to respond to your inquiries.
              </li>
            </ul>
            <br>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong>How do we use and share your personal information?</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              heedi collects and shares your personal information through:
            </span></span></p>

            <ul>
              <li style="line-height: 1.5;">
                Social media Cookies
              </li>
            </ul>
            <br>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              More information about our data collection and sharing practices can be found in this privacy notice
            </span></span></p>
            
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              You may contact us by email at ccpa@heedi.com or by referring to the contact details at the bottom of this document.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong>Will your information be shared with anyone else?</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal data.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              heedi has disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;">
                Category A. Identifiers, such as contact details, like your real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name.
              </li>
              <li style="line-height: 1.5;">
                Category B. Personal information, as defined in the California Customer Records law, such as your name, contact information, education, employment, employment history and financial information.
              </li>
              <li style="line-height: 1.5;">
                Category C. Characteristics of protected classifications under California or federal law, such as gender or date of birth.
              </li>
              <li style="line-height: 1.5;">
                Category F. Internet or other electronic network activity information, such as browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements.
              </li>
              <li style="line-height: 1.5;">
                Category G. Geolocation data, such as device location.
              </li>
            </ul>
            <br>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "<a data-custom-class="link" @click="scrollToAnchorPoint('#whoshare')">WHO WILL YOUR INFORMATION BE SHARED WITH?</a>"
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              heedi has not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. heedi will not sell personal information in the future belonging to website visitors, users and other consumers.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <strong>Your rights with respect to your personal data</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <u>Right to request deletion of the data - Request to delete</u>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              <u>Right to be informed - Request to know</u>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> 
              Depending on the circumstances, you have a right to know:
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;">
                whether we collect and use your personal information;
              </li>
              <li style="line-height: 1.5;">
                the categories of personal information that we collect;
              </li>
              <li style="line-height: 1.5;">
                the purposes for which the collected personal information is used;
              </li>
              <li style="line-height: 1.5;">
                whether we sell your personal information to third parties;
              </li>
              <li style="line-height: 1.5;">
                the categories of personal information that we sold or disclosed for a business purpose;
              </li>
              <li style="line-height: 1.5;">
                the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
              </li>
              <li style="line-height: 1.5;">
                the business or commercial purpose for collecting or selling personal information.
              </li>
            </ul>
            <br>
        
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              We will not discriminate against you if you exercise your privacy rights.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <u>Verification process</u>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate. 
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <u>Other privacy rights</u>
            </span></span></p>
            
            <ul>
              <li style="line-height: 1.5;">
                you may object to the processing of your personal data
              </li>
              <li style="line-height: 1.5;">
                you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data
              </li>
              <li style="line-height: 1.5;">
                you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
              </li>
              <li style="line-height: 1.5;">
                you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
              </li>
            </ul>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">  
              To exercise these rights, you can contact us by email at ccpa@heedi.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
            </span></span></p>
            <br>
            <br>
            <h2><span ref="#policyupdates">13. DO WE MAKE UPDATES TO THIS NOTICE?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <em><strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </span></span></p>
            <br>
            <br>
            <h2><span ref="#contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              If you have questions or comments about this notice, you may email us at privacy@heedi.com
              Anderson Internet LLC (heedi)
              2634 W Rice St, Chicago, IL 60622, USA
              Chicago, 60622
              United States
            </span></span></p>
            <br>
            <br>
            <h2><span ref="#request">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: <a href="http://www.heedi.com/privacy" target="_blank" data-custom-class="link">http://www.heedi.com/privacy</a>
            </span></span></p>
            
            <div style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">This privacy policy was created using <a style="color: rgb(48, 48, 241) !important;" href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly’s Privacy Policy Generator</a>.</div>

          </div>

        </v-card-text>
      </v-card>
      <br>
      <br>

      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>
  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  // import imageModule from '@/store/image'
  import searchModule from '@/store/search'
  import settingsModule from '@/store/settings'
  import VueFooter from '@/components/VueFooter'

  import { mapActions } from 'vuex'


  let id = 1;

  export default {
    name: "privacy",
    order: 16,
    components: {
      VueFooter,

    },
    data () {
      return {
        overlay: false,
        showFooterBool: false,
        heroImageSrc: "",
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8'],


        list: [
          {
            name: "task 1",
            tasks: [
              {
                name: "task 2",
                tasks: []
              }
            ]
          },
          {
            name: "task 3",
            tasks: [
              {
                name: "task 4",
                tasks: []
              }
            ]
          },
          {
            name: "task 5",
            tasks: []
          }
        ],
      

        showBlock2: false,
        showBlock3: false,
        showBlock4: false,

        //vuedraggable variables
        enabled: true,
        dragging: false,
        /*
        list: [
          { name: "John", id: 0 },
          { name: "Joao", id: 1 },
          { name: "Jean", id: 2 }
        ],
        */
      }
    },
    computed: {
      draggingInfo() {
        return this.dragging ? "under drag" : "";
      },
      elements: {
          get() {
            return this.$store.state.nested.elements;
          },
          set(value) {
            this.$store.commit("nested/updateElements", value, {root: true});
          }
        },
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('image', [
        'getHeroImage'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon'
      ]),
      showFooter () {
        this.showFooterBool = true;
      },
      add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
      },
      replace: function() {
        this.list = [{ name: "Edgard", id: id++ }];
      },
      checkMove: function(e) {
        window.console.log("Future index: " + e.draggedContext.futureIndex);
      },
      scrollToAnchorPoint(refName) {
        const el = this.$refs[refName]
        el.scrollIntoView({ behavior: 'smooth'})
      }
    },
    watch: {

    },
    async beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      //if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      //if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
  
    },
    created () {
      // gtag('config', 'G-H9RD8W4MLR', {'page_path': '/about'});
      this.autoSignIn()
      this.setShowSearchIcon(true)
    },
    mounted () {
    },
    updated () {

    }
  }
</script>

<style scoped>

  .privacyNoticeText {
    padding-left: 10px;
    padding-right: 10px;
  }

  .about {
    min-height: 400px;
  }

  .blockWrapper {
    background-color: white;
    padding: 30px;
    
    margin: 0 auto;
  }



  .searchContainer {
    text-align: center;

  }


  ul {
        list-style-type: square;
  }
  ul > li > ul {
    list-style-type: circle;
  }
  ul > li > ul > li > ul {
    list-style-type: square;
  }
  ol li {
    font-family: Arial ;
  }
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }


  .moreLink {
    display: block;
    width: 30%;
    max-width: 200px;
    border: 2px solid #999;
    padding: 3px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    color: #555;
    border-radius: 10px;
    background-color: #ddd;
    
  }
  .moreLink:hover {
    background-color: #000;
    color: white;
  }

  .downIcon {
    color: #777;
  }


  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .aboutContent {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
    font-weight: 400;
  }

  .searchContainer {
    text-align: center;
    font-size: .7em;

  }

  #heediSupportImg {
    width: 90%;
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .heediImgHolder {
    padding: 25px;
    text-align: center;
  }

  @media screen and (min-width: 400px) {

    #heediSupportImg {
      width: 240px;
    }

  }

  @media screen and (min-width: 510px) {


    .flexContainer {
      flex-direction: row;
      text-align: left;
    }
    .privacyNoticeText {
      padding-left: 40px;
      padding-right: 40px;
    }
  }


</style>